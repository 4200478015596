<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Assembly Preparations">
      <template slot="module-nav-logo">
        <img
            v-if="isDaiwaLicense"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div
      v-if="assemblyPreparationsStatus === 'success'"
      class="flex-1 d-flex overflow-y-auto"
    >
      <div
        class="ant-glass-background radius-0 ant-border-right d-flex flex-column"
      >
        <div style="height: 48px"></div>
        <div
          id="apScrollItems"
          ref="ap-scroll-items"
          class="overflow-y-auto flex-1"
        >
          <div
            v-for="module in assemblyPreparationsModules"
            :key="module.id"
            style="height: 48px; min-width: 150px; font-size: 12px"
            class="d-flex px-2 py-1 assembly-preparation-module-item ant-border-bottom"
            :class="{ active: module === selectedModule }"
            @click="selectModule(module)"
          >
            <div class="d-flex flex-column flex-1 font-weight-bold">
              <div class="flex-1">
                {{ module.build_nr ? module.build_nr : '-' }}
              </div>
              <div class="flex-1"></div>
            </div>
            <div class="d-flex flex-column flex-1 align-end">
              <div class="flex-1">{{ module.module_id }}</div>
              <div class="font-italic flex-1 text-no-wrap">
                {{ module.module_type }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="simple-fade" mode="out-in">
        <div v-if="!selectedModule" class="d-flex flex-column flex-1">
          <div class="d-flex ant-glass-background radius-0 ant-border-bottom">
            <div
              v-for="location in assemblyPreparationsHallLocations"
              :key="location.id"
              style="height: 48px; width: 96px"
              class="d-flex align-center justify-center ant-border-right"
            >
              <div class="d-flex flex-column justify-center align-center">
                <div style="font-size: 12px">
                  {{ location.assembly_location }}
                </div>
                <div class="font-italic" style="font-size: 12px">
                  {{ location.description }}
                </div>
              </div>
            </div>
          </div>
          <div ref="ap-scroll-grid" class="overflow-y-auto flex-1">
            <div
              v-for="module in assemblyPreparationsModules"
              :key="module.id"
              class="d-flex"
              style="height: 48px"
            >
              <div
                v-for="location in assemblyPreparationsHallLocations"
                :key="location.id"
                style="
                  height: 48px;
                  width: 96px;
                  border-right: solid 1px gainsboro;
                  border-bottom: solid 1px gainsboro;
                  background-color: white;
                "
                class="d-flex align-center justify-center"
              >
                <v-icon :color="getLocationStatusColor(module, location)">{{
                  getLocationStatusIcon(module, location)
                }}</v-icon>
              </div>
            </div>
          </div>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                color="primary"
                style="right: 50px; bottom: 100px; position: absolute"
                v-on="on"
                @click="planBatchDialog = true"
              >
                <v-icon dense> mdi-package-variant-plus </v-icon>
              </v-btn>
            </template>
            <span>Plan batch</span>
          </v-tooltip>
          <dhme-assembly-preparations-batch-dialog
            :displayed="planBatchDialog"
            @closeDialog="planBatchDialog = false"
            @batchPlanned="planBatchDialog = false"
          />
        </div>
        <dhme-assembly-preparations-module-details
          v-else
          :module="selectedModule"
          @closeView="deselectModule"
        />
      </transition>
    </div>
    <div v-else class="d-flex align-center justify-center flex-1">
      <ant-loading />
    </div>
  </div>
</template>

<script>
import { DHME_ASSEMBLY_PREPARATIONS } from '@/modules/modules';
import { mapGetters } from 'vuex';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import AntLoading from '@/components/AntLoading.vue';
import DhmeAssemblyPreparationsModuleDetails from '@/modules/daiwa-house-modular-europe/AssemblyPreparations/DhmeAssemblyPreparationsModuleDetails.vue';
import DhmeAssemblyPreparationsBatchDialog from '@/modules/daiwa-house-modular-europe/AssemblyPreparations/DhmeAssemblyPreparationsBatchDialog.vue';

export default {
  name: 'DhmeAssemblyPreparations',
  components: {
    DhmeAssemblyPreparationsBatchDialog,
    DhmeAssemblyPreparationsModuleDetails,
    AntLoading,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      selectedModule: null,
      planBatchDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'assemblyPreparationsModules',
      'assemblyPreparationsHall',
      'assemblyPreparationsHallLocations',
      'assemblyPreparationsStatus',
      'assemblyPreparationsLocationStatuses',
        'isDaiwaLicense'
    ]),
  },
  async mounted() {
    await this.$store.dispatch(
      'fetchAssemblyPreparationsProjectAssemblyPhase',
      {
        projectId: this.project.id,
      }
    );
    await this.$store.dispatch('fetchAssemblyPreparationsModuleAssemblyTasks', {
      projectId: this.project.id,
    });
    this.$refs['ap-scroll-grid'].addEventListener(
      'scroll',
      this.handleScrollEvent
    );
  },
  beforeDestroy() {
    this.$refs['ap-scroll-grid'].removeEventListener(
      'scroll',
      this.handleScrollEvent
    );
  },
  methods: {
    getLocationStatusIcon(module, location) {
      let record = this.assemblyPreparationsLocationStatuses.find(
        (item) =>
          item.module_id === module.module_id &&
          item.assembly_location === location.id
      );
      if (record) {
        switch (record.status) {
          case 'success':
            return 'mdi-check-circle-outline';
          case 'alert':
            return 'mdi-alert-circle-outline';
        }
      }
    },
    getLocationStatusColor(module, location) {
      let record = this.assemblyPreparationsLocationStatuses.find(
        (item) =>
          item.module_id === module.module_id &&
          item.assembly_location === location.id
      );
      if (record) {
        switch (record.status) {
          case 'success':
            return this.$vuetify.theme.themes.light.success;
          case 'alert':
            return this.$vuetify.theme.themes.light.info;
        }
      }
    },
    deselectModule() {
      this.selectedModule = null;
      setTimeout(() => {
        this.$refs['ap-scroll-grid'].addEventListener(
          'scroll',
          this.handleScrollEvent
        );
      }, 500);
    },
    selectModule(module) {
      if (this.selectedModule === module) {
        this.deselectModule();
      } else {
        if (this.selectedModule === null) {
          this.$refs['ap-scroll-grid'].removeEventListener(
            'scroll',
            this.handleScrollEvent
          );
        } else {
          this.selectedModule = null;
        }

        this.$nextTick(() => {
          this.selectedModule = module;
        });
      }
    },
    handleScrollEvent() {
      this.$refs['ap-scroll-items'].scrollTop =
        this.$refs['ap-scroll-grid'].scrollTop;
    },
  },
};
</script>

<style scoped lang="scss">
.assembly-preparation-module-item {
  transition: 200ms ease-out;
  cursor: pointer;
  position: relative;
  z-index: 10;

  &.active {
    background: #f2f2f2;
    border-left: solid 2px var(--v-primary-base);
  }

  &:hover {
    background: #f2f2f2;
  }
}

#apScrollItems::-webkit-scrollbar {
  display: none;
}
</style>
